import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import appleDownload from '../../assets/banners/app-store.png';
import androidDownload from '../../assets/banners/google-play.png';
import envs from '../../common/envs';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import useGlobalState from '../report/hooks/use-global-state';

function DownloadSheet() {
  const [, { setDownloadApp, setReportState }] = useGlobalState();

  useModalManage({
    modalKey: 'download-app-sheet',
    onBlocked: () => {
      setReportState(null);
    },
  });

  return (
    <BottomSheet
      className='fixed z-20'
      open
      onDismiss={() => {
        setDownloadApp(false);
      }}
    >
      <div className='p-5'>
        <div className='flex flex-col gap-8'>
          <div className='text-lg font-semibold text-center'>앱 다운로드</div>
          <div className='flex-col flex-1 gap-6'>
            <div className='flex gap-3'>
              <div
                className='flex-1 cursor-pointer  flex justify-end'
                onClick={() => {
                  window.open(envs.mobileAppleLink, '_blank');
                }}
              >
                <div className='max-w-[22rem]'>
                  <img src={appleDownload} />
                </div>
              </div>
              <div
                className='flex-1 cursor-pointer flex'
                onClick={() => {
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.aquadesk.ttopet`,
                    '_blank',
                  );
                }}
              >
                <div className='max-w-[22rem]'>
                  <img src={androidDownload} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}

export default DownloadSheet;
