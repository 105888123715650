import { memo, useState } from 'react';

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import classNames from 'classnames';
import { FiMoreVertical } from 'react-icons/fi';
import PrivateCommentBadge from '../../components/badge/PrivateCommentBadge';
import { IconComment } from '../../components/Icons';
import {
  CommentFragment,
  useDeleteCommentMutation,
} from '../../generated/graphql';
import { onError } from '../../utils/apollo-utils';
import { dateDistance } from '../../utils/date';
import { pretty } from '../../utils/debug';
import useAuth from '../auth/use-auth';
import useBlockState from '../report/hooks/use-block-state';
import useGlobalState from '../report/hooks/use-global-state';
import ProfileUser from '../users/ProfileUser';
import { initiateLogin } from '../users/utils/auth-actions';
import { removeComment } from './comment-caches';
import {
  getRepliesQueryParams,
  getTopCommentQueryParams,
} from './comment-params';
import CommentEditForm from './CommentEditForm';
import CommentLIkeButton from './CommentLIkeButton';
import CommentReplies from './CommentReplies';
import CommentReplyForm from './CommentReplyForm';
import UserContentRenderer from './UserContentRenderer';

function Comment({
  targetId,
  comment,
  parentComment,
  blocked,
}: {
  targetId: string;
  comment?: CommentFragment;
  parentComment?: CommentFragment;
  blocked?: boolean;
}) {
  const [{ isLoggedIn, userId }] = useAuth();
  const [, { setReportState }] = useGlobalState();
  const [, { blockUserId }] = useBlockState();

  const leafComment = !!parentComment;

  const [editMode, setEditMode] = useState(false);
  const [openCommentForm, setOpenCommentForm] = useState(false);
  const [deleteComment] = useDeleteCommentMutation({
    onError,
    update(cache, { data }) {
      const replyToCommentId = parentComment?.id;

      const variables = replyToCommentId
        ? getRepliesQueryParams({
            commentId: replyToCommentId,
          })
        : getTopCommentQueryParams({
            targetId,
          });

      removeComment({
        cache,
        newData: data?.deleteComment?.data,
        variables,
      });
    },
  });

  if (!comment || !comment?.id) {
    return null;
  }

  const creatorId = comment?.attributes?.creator?.data?.id;
  const isOwner = userId && userId === creatorId;

  return (
    <div
      key={comment.id}
      className={classNames('flex flex-col gap-4', {
        'pr-[6px] lg:pr-[8px] border-b': !leafComment,
      })}
    >
      <div className='flex flex-col gap-1'>
        {editMode ? (
          <CommentEditForm
            comment={comment}
            onSuccess={() => {
              setEditMode(false);
            }}
          />
        ) : (
          <>
            <div
              className={classNames('flex justify-between', {
                ' pr-[2px] lg:pr-[12px]': !leafComment,
              })}
            >
              <div className='flex overflow-hidden'>
                <ProfileUser
                  user={comment.attributes?.creator?.data}
                  blocked={blocked}
                />
              </div>
              <div className='flex-1'></div>
              <div className='flex-none flex gap-2'>
                <div className='flex-none text-sm opacity-50 flex-center-y'>
                  {dateDistance(comment.attributes?.createdAt)}
                </div>
                <div className='flex-none text-sm flex gap-2'>
                  <Menu
                    menuButton={
                      <MenuButton>
                        <FiMoreVertical size={18} />
                      </MenuButton>
                    }
                    transition
                  >
                    {isOwner ? (
                      <>
                        <MenuItem
                          onClick={() => {
                            setEditMode(true);
                          }}
                        >
                          댓글 수정
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const yes = window.confirm('삭제할까요?');
                            if (!yes) return;

                            if (comment.id && creatorId) {
                              deleteComment({
                                variables: {
                                  id: comment.id,
                                  creator: creatorId,
                                },
                              });
                            }
                          }}
                        >
                          댓글 삭제
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem
                          onClick={() => {
                            setReportState({
                              reportKey: `comment-${comment?.id}`,
                              accusedUser: creatorId,
                              creator: userId,
                              snapshot: pretty(comment),
                              // images: listToIds(comment?.images?.data),
                            });
                          }}
                        >
                          신고하기
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const yes = window.confirm('차단할까요?');
                            if (!yes) return;

                            blockUserId(creatorId);
                          }}
                        >
                          차단하기
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
            {blocked ? (
              <div
                className={classNames('break-words', {
                  ' pr-[10px] lg:pr-[22px]': !leafComment,
                })}
              >
                [차단된 사용자입니다.]
              </div>
            ) : (
              <div
                className={classNames(
                  'break-words ',
                  'pl-12 pr-[10px] lg:pr-[22px]',
                )}
              >
                {comment.attributes?.replyToUser?.data && (
                  <span className='font-bold'>
                    {`@${comment.attributes?.replyToUser.data?.attributes?.displayName} `}
                  </span>
                )}

                {comment?.attributes?.private && <PrivateCommentBadge />}
                {/* <div className='font-bold text-lg'>{comment.id}</div> */}
                {!blocked ? (
                  <UserContentRenderer text={comment.attributes?.content} />
                ) : (
                  <div>[차단된 사용자입니다.]</div>
                )}
              </div>
            )}

            {/* actions */}
            <div className='flex items-center gap-[30px] text-sm pl-11'>
              <CommentLIkeButton comment={comment} />

              <div
                className={classNames('flex gap-2 cursor-pointer')}
                onClick={() => {
                  if (!isLoggedIn) {
                    initiateLogin();
                    return;
                  }

                  setOpenCommentForm(!openCommentForm);
                }}
              >
                <IconComment />
                {/* <span>{comment.attributes?.commentCount || 0}</span> */}
              </div>
            </div>
          </>
        )}
      </div>

      {leafComment && parentComment && openCommentForm && (
        <CommentReplyForm
          targetId={targetId}
          comment={parentComment}
          replyTo={comment?.attributes?.creator?.data}
          replyToComment={comment.id}
          onLeafComment={leafComment}
          onSuccess={() => {
            // open comments
            setOpenCommentForm(false);
          }}
          onCancel={() => {
            setOpenCommentForm(false);
          }}
        />
      )}

      {!leafComment && (
        <CommentReplies
          targetId={targetId}
          comment={comment}
          openCommentForm={openCommentForm}
          setOpenCommentForm={setOpenCommentForm}
        />
      )}
    </div>
  );
}

export default memo(Comment);
