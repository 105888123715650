import {
  ApolloClient,
  ApolloLink,
  from,
  // createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { uniqBy } from 'lodash';
import envs from '../common/envs';
import { RefreshTokenDocument } from '../generated/graphql';

const httpLink = createUploadLink({
  uri: envs.BackendUrl,
  fetchOptions: {
    credentials: 'include',
  },
});

const errorLinkRefreshToken = onError(() => {
  // eslint-disable-next-line
  console.log('error handle for refresh token');

  // refresh token error
  window.location.href = '/';
});

export function createClient() {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLinkRefreshToken, httpLink] as ApolloLink[]),
    cache: new InMemoryCache(),
  });

  return client;
}

let accessToken: undefined | string | null = null;

export function setAccessToken(value: typeof accessToken) {
  accessToken = value;
}

export function getAccessToken() {
  return accessToken;
}

const errorLink = onError(
  // eslint-disable-next-line
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      // @ts-ignore
      const { statusCode } = networkError;

      if (statusCode === 401) {
        const client = createClient();
        return fromPromise(
          client
            .mutate({
              mutation: RefreshTokenDocument,
            })
            .then((r) => {
              setAccessToken(r.data.refreshToken?.jwt);
              return forward(operation);
            }),
        ).flatMap(() => {
          return forward(operation);
        });
      }

      // eslint-disable-next-line
      console.log(`[Network error]: ${networkError}`);
    }

    return undefined;
  },
);

const createAuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const at = getAccessToken();
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...(at
            ? {
                authorization: `Bearer ${at}`,
              }
            : null),
        },
      };
    });

    return forward(operation);
  });
};

export function useClient() {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLink, createAuthMiddleware(), httpLink] as ApolloLink[]),
    cache: new InMemoryCache({
      typePolicies: {
        Trade: {
          fields: {
            post: {
              merge: true,
            },
          },
        },
        Query: {
          fields: {
            aquatics: {
              keyArgs: ['filters', 'sort'],
              // https://stackoverflow.com/a/66613465/2330799
              // merge(existing, incoming, { mergeObjects }) {
              //   return mergeObjects(existing, incoming);
              // },
              merge(existing, incoming) {
                // load more and appending to the cached list
                const data = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data,
                };
              },
            },
            trades: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [...(incoming?.data || []), ...(existing?.data || [])],
                  (i) => i.__ref,
                );

                // console.log('trades. query cache data', data);

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            notis: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [...(incoming?.data || []), ...(existing?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            // adding incoming at the end for main post board
            posts: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            usersPermissionsUsers: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const comments = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data: comments,
                };
              },
            },
            comments: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const comments = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data: comments,
                };
              },
            },
          },
        },
      },
    }),
  });

  return client;
}
