import { useEffect } from 'react';
import useAppConfig from '../../app/use-app-configs';
import { useNavBlocker } from './use-nav-blocker';

export function useModalManageWoBlocker({ modalKey }: { modalKey: string }) {
  const [, { openModalWithKey, closeModalWithKey }] = useAppConfig();

  useEffect(() => {
    openModalWithKey(modalKey);

    return () => {
      closeModalWithKey(modalKey);
    };
  }, []);
}

export function useModalManage({
  modalKey,
  onBlocked,
}: {
  modalKey: string;
  onBlocked?: () => void;
}) {
  const [, { openModalWithKey, closeModalWithKey }] = useAppConfig();

  useEffect(() => {
    openModalWithKey(modalKey);

    return () => {
      closeModalWithKey(modalKey);
    };
  }, []);

  useNavBlocker({
    block: !!onBlocked,
    onBack: onBlocked,
  });
}
