import { isEmpty, size } from 'lodash';
import { memo, useState } from 'react';

import { MdArrowDropDown } from 'react-icons/md';
import appConfig from '../../app/appConfig';
import LoadingIcon from '../../components/loading/LoadingIcon';
import { CommentFragment, useCommentsQuery } from '../../generated/graphql';
import { convToRender } from '../../types/gql-enhanced-types';
import useBlockState from '../report/hooks/use-block-state';
import Comment from './Comment';
import CommentReplyForm from './CommentReplyForm';
import { getRepliesQueryParams } from './comment-params';

const { pageSize } = appConfig.reply;

function CommentReplies({
  targetId,
  comment,
  openCommentForm,
  setOpenCommentForm,
}: {
  targetId: string;
  comment: CommentFragment;
  openCommentForm: boolean;
  setOpenCommentForm: (v: boolean) => void;
}) {
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [, { filterBlockedComment }] = useBlockState();

  const replyCount = comment.attributes?.commentCount || 0;
  const hasComments = replyCount > 0;

  const [showComments, setShowComments] = useState(false);

  const variables = getRepliesQueryParams({
    commentId: comment.id,
  });

  const { data, loading, fetchMore } = useCommentsQuery({
    variables,
    onCompleted: () => {
      setLoadingMore(false);
    },
    skip: !showComments,
  });

  const countToShow = page * pageSize;
  const comments = data?.comments?.data;
  const commentsToShow = data?.comments?.data.slice(0, countToShow);

  const hasMore = size(commentsToShow) < size(comments);

  if (loading) {
    return (
      <div className='flex-center p-5'>
        <LoadingIcon />
      </div>
    );
  }

  const hasCommentsFromQuery = !isEmpty(commentsToShow);

  if (
    // NOTE: disabled it since it prevents showing comment form replying to a top level comment after deleting the last reply for the comment.
    // (showComments && !hasCommentsFromQuery) ||
    !comment ||
    !comment?.id
  ) {
    return null;
  }

  return (
    <div>
      {openCommentForm && (
        <div className='mb-2'>
          <CommentReplyForm
            onLeafComment
            targetId={targetId}
            comment={comment}
            replyTo={comment.attributes?.creator?.data}
            onSuccess={() => {
              setShowComments(true);
              setOpenCommentForm(false);
            }}
            onCancel={() => {
              setOpenCommentForm(false);
            }}
          />
        </div>
      )}

      {!showComments && hasComments && (
        <div
          className='bg-base-100 p-2 flex gap-3 font-bold cursor-pointer text-blue-600 pl-11 pb-4'
          onClick={() => {
            setShowComments(true);
          }}
        >
          <span className='text-sm flex gap-2'>
            <MdArrowDropDown size={20} /> {replyCount} 댓글 보기
          </span>
        </div>
      )}

      {showComments && hasCommentsFromQuery && (
        <div className='flex flex-col gap-[20px] pl-[30px] pt-[20px] pb-[10px] bg-base-100 rounded'>
          {commentsToShow?.map((commentItem) => (
            <Comment
              targetId={targetId}
              key={commentItem.id}
              comment={commentItem}
              parentComment={comment}
              blocked={!filterBlockedComment(convToRender(commentItem))}
            />
          ))}

          {loadingMore && (
            <div className='flex-center-x p-10'>
              <LoadingIcon />
            </div>
          )}

          {hasMore && (
            <div
              className='bg-base-100 py-2 flex gap-3 font-bold  text-blue-600 cursor-pointer'
              onClick={async () => {
                if (!loading || !loadingMore) {
                  setLoadingMore(true);

                  const nPage = page + 1;

                  await fetchMore({
                    variables: {
                      pagination: {
                        page: nPage,
                        pageSize: pageSize + 1,
                      },
                    },
                  });

                  setLoadingMore(false);

                  setPage(nPage);
                }
              }}
            >
              <span className='text-sm'>더 불러오기</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default memo(CommentReplies);
