import DownloadSheet from '../mobile-app/DownloadSheet';
import useGlobalState from './hooks/use-global-state';
import ModalReportForm from './ModalReportForm';

function GlobalFeatures() {
  const [{ reportState, globalState }] = useGlobalState();
  const { openDownload } = globalState;

  const open = !!reportState?.accusedUser;

  return (
    <>
      <div className='h-0 max-w-2xl'>
        {open && <ModalReportForm />}
        {openDownload && <DownloadSheet />}
      </div>
    </>
  );
}

export default GlobalFeatures;
