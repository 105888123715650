import { isEmpty, size } from 'lodash';
import { memo } from 'react';
import { Waypoint } from 'react-waypoint';

import { IoFish } from 'react-icons/io5';
import appConfig from '../../app/appConfig';
import { usePostsQuery } from '../../generated/graphql';
import { usePageLoadMore } from '../../graphql/use-page-load-more';
import { Id } from '../users/type';

import useAppConfig from '../../app/use-app-configs';
import { useAppRouter } from '../../common/hooks/use-app-router';
import useSearchState from '../../common/hooks/use-search-state';
import LoadingIcon from '../../components/loading/LoadingIcon';
import SkelPostList from '../../components/skeleton/SkelPostList';
import { convToRender } from '../../types/gql-enhanced-types';
import { notNull } from '../../utils/array';
import TradePostItem from '../trade/TradePostItem';
import PostListItem from './PostListItem';
import { getPostQueryParams } from './posts-query-params';

const { pageSize } = appConfig.post;

function PostsListFull({ creatorId }: { creatorId?: Id }) {
  const {
    params: { boardType },
  } = useAppRouter();

  const [{ paramBoardConfig }] = useAppConfig();
  const [{ selectedTag, keyword }] = useSearchState({
    boardConfig: paramBoardConfig,
  });

  const listQueryVariables = getPostQueryParams({
    creatorId,
    category: boardType,
    keyword,
    tags: selectedTag,
  });

  const { data, loading, fetchMore } = usePostsQuery({
    fetchPolicy: 'cache-first',
    variables: listQueryVariables,
  });

  const posts = (data?.posts?.data || [])
    .map(convToRender)
    .filter((i) => i?.deleted !== true)
    .filter(notNull);

  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading,
    pageSize,
    listLength: size(posts),
  });

  const noPosts = isEmpty(posts);

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      className='flex-1 flex flex-col overflow-hidden-x'
    >
      {loading && <SkelPostList />}

      {!loading &&
        posts.map((post) => {
          const trade = convToRender(post?.trades?.data?.[0]);

          if (trade) {
            return <TradePostItem key={post.id} post={post} />;
          }

          return <PostListItem key={post.id} post={post} />;
        })}

      {!loading && noPosts && (
        <div className='flex-col flex-1 flex-center'>
          <div className='font-primary text-6xl'>텅!</div>
          <IoFish size={20} />
        </div>
      )}

      {loadingMore && (
        <div className='flex-center-x p-10'>
          <LoadingIcon />
        </div>
      )}

      {hasMore && <Waypoint scrollableAncestor='window' onEnter={loadMore} />}
    </div>
  );
}

export default memo(PostsListFull);
