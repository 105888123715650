import { FcGoogle } from 'react-icons/fc';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { VscChromeClose } from 'react-icons/vsc';
import KakaoLogin from 'react-kakao-login';
import envs from '../../common/envs';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/Modal';
import { Platform, useConnectAuthMutation } from '../../generated/graphql';
import { getErrorMsg } from '../../graphql/utils/error';
import { unlinkApp } from '../../libs/kakao';
import useAuth from '../auth/use-auth';

function SignInModal({ setOpen }: { setOpen: (n: boolean) => void }) {
  const [, { handleLogin }] = useAuth();

  const [connectType, setConnectType] = useState<'kakao' | 'google'>();
  const [connect, { error }] = useConnectAuthMutation({
    onCompleted: async (connectData) => {
      if (connectData?.connect.user) {
        await handleLogin(connectData?.connect);
        setOpen(false);
      }
      setConnectType(undefined);
    },
    onError: async (err1) => {
      const isEmailIssue = !!err1.graphQLErrors?.find((e) =>
        [
          'STRAPI_KAKAO_EMAIL_MISSING_ERROR',
          // 'STRAPI_KAKAO_EMAIL_NOT_VERIFIED_ERROR',
          // @ts-ignore
        ].includes(e?.extensions?.code || ''),
      );

      if (isEmailIssue) {
        unlinkApp();
      }

      setConnectType(undefined);
    },
  });

  // handle google auth result
  useEffect(() => {
    const handleResult = (event: MessageEvent) => {
      if (event.data?.googleAuth !== 1) return;

      const success = event.data?.success;

      if (success) {
        handleLogin(event.data?.connect);
        setConnectType(undefined);
        setOpen(false);
      } else {
        const msg = event.data?.msg;
        toast.error(`로그인에 실패했습니다: ${msg}`);
      }
    };

    window.addEventListener('message', handleResult, false);
    return () => {
      window.removeEventListener('message', handleResult);
    };
  }, []);

  useModalManage({ modalKey: 'sign-in-modal' });

  return (
    <Modal
      open
      className='lg:w-[400px]'
      onClickBackdrop={() => {
        setOpen(false);
      }}
    >
      <div className='flex flex-col h-full p-5 lg:p-10'>
        <div className='flex justify-end flex-none text-3xl text-center lg:hidden'>
          <span
            className='cursor-pointer'
            onClick={() => {
              setOpen(false);
            }}
          >
            <VscChromeClose className='w-6 opacity-70' />
          </span>
        </div>
        <div className='flex-auto flex-center'>
          <div className='flex flex-col gap-4'>
            <h3 className='text-3xl font-bold text-center'>환영해요~ 👋</h3>
            <div className='flex flex-col w-full gap-3 py-4'>
              <div
                onClick={() => {
                  if (connectType != null) return;

                  setConnectType('kakao');
                }}
              >
                <KakaoLogin
                  className='w-full bg-[#ffeb00] p-3 flex'
                  style={{}}
                  onSuccess={async (e) => {
                    setConnectType('kakao');

                    await connect({
                      variables: {
                        input: {
                          code: e.response.access_token,
                          type: 'kakao',
                          platform: Platform.Web,
                        },
                      },
                    });
                  }}
                  token={envs.kakaoJsKey}
                  onFail={() => {
                    unlinkApp();
                  }}
                >
                  <div className='flex-1 w-full gap-2 flex-center'>
                    {connectType === 'kakao' ? (
                      <Loading />
                    ) : (
                      <RiKakaoTalkFill size={22} />
                    )}
                    <span className='font-bold text-[1.1rem]'>
                      카카오톡으로 시작하기
                    </span>
                  </div>
                </KakaoLogin>
              </div>

              <button
                className={classNames(
                  'btn btn-lg gap-2 bg-white hover:bg-white border-0 text-black lg:bg-base-200 lg:hover:bg-base-200',
                )}
                onClick={async () => {
                  window.open(
                    `/auth/google/login`,
                    'extension_popup',
                    'width=400,height=500,status=no,scrollbars=yes,resizable=no',
                  );

                  // googleLogin();
                }}
              >
                {connectType === 'google' ? (
                  <Loading />
                ) : (
                  <FcGoogle size='1.4rem' />
                )}
                구글로 시작하기
              </button>
            </div>
            {getErrorMsg(error) && (
              <div className='text-error'>{getErrorMsg(error)}</div>
            )}
            <p className='pt-4 opacity-70'>
              <span className='text-sm'>
                {
                  '로그인을 하고 또펫의 모든 서비스를 이용해보세요. 로그인을 하시면 또펫의 이용약관에 동의하는 것으로 간주합니다.'
                }
                <a
                  href={envs.tos}
                  className='link'
                  rel='noreferrer'
                  target='_blank'
                >
                  이용약관
                </a>
                {' / '}
                <a
                  href={envs.privacy}
                  className='link'
                  rel='noreferrer'
                  target='_blank'
                >
                  개인정보처리방침
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// eslint-disable-next-line
function SignInModalWrap(props: any) {
  return <SignInModal {...props} />;
}

export default SignInModalWrap;
