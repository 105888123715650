import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import Four04Page from '../components/error/Four04Page';
import AquaticsListPage from '../modules/aquatics/AquaticsListPage';
import AquaticViewPage from '../modules/aquatics/AquaticViewPage';
import HomePage from '../modules/home/HomePage';
import ForceNotiPage from '../modules/pages/ForceNotiPage';
import PrivacyPolicy from '../modules/pages/PrivacyPolicy';
import TermsPage from '../modules/pages/TermsPage';
import PostListPage from '../modules/post/PostListPage';
import PostViewPage from '../modules/post/PostViewPage';
import SearchPage from '../modules/search/SearchPage';
import SearchPeoplePage from '../modules/search/SearchPeoplePage';
import TradeHomePage from '../modules/trade/TradeHomePage';
import TradeViewPage from '../modules/trade/TradeViewPage';
import ProfileByIdPage from '../modules/users/ProfileByIdPage';
import ProfileEditPage from '../modules/users/ProfileEditPage';
import ProfilePage from '../modules/users/ProfilePage';
import SettingsPage from '../modules/users/SettingsPage';
import SuperAdminPage from '../modules/users/SuperAdminPage';
import Layout from './Layout';
import NotificationsPage from './nav/NotificationsPage';
import RootLayout from './RootLayout';

// { /* <Route path='auth/twitter' element={<TwitterLoginPage />} /> */ }

export default createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/auth/*' element={<RootLayout />} />
      <Route path='/' element={<Layout />}>
        <Route path='notifications' element={<NotificationsPage />} />

        <Route index element={<HomePage />} />
        <Route path='profile'>
          <Route index element={<ProfilePage />} />
          <Route path=':username' element={<ProfileByIdPage />} />
          <Route path='edit' element={<ProfileEditPage />} />
        </Route>
        <Route path='trades'>
          <Route index element={<TradeHomePage />} />
          <Route path=':postId' element={<TradeViewPage />} />
        </Route>
        <Route path='posts'>
          <Route index element={<PostListPage />} />
          <Route path=':boardType'>
            <Route index element={<PostListPage />} />
            <Route path=':postId' element={<PostViewPage />} />
          </Route>
        </Route>
        {/* TODO settings page  */}
        <Route path='settings' element={<SettingsPage />} />
        <Route path='super' element={<SuperAdminPage />} />
        <Route path='settings/force-noti' element={<ForceNotiPage />} />
        <Route path='search' element={<SearchPage />} />
        <Route path='people' element={<SearchPeoplePage />} />

        <Route path='page'>
          <Route path='terms' element={<TermsPage />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
        </Route>

        <Route path='pet'>
          <Route path='aquatics' element={<AquaticsListPage />} />
          <Route path='aquatics/:aqId' element={<AquaticViewPage />} />
        </Route>

        <Route path='*' element={<Four04Page />} />
      </Route>
    </Route>,
  ),
);
