import classNames from 'classnames';
import { memo } from 'react';

import { IoMdHeart } from 'react-icons/io';
import { TPost } from '../../types/gql-enhanced-types';
import useAuth from '../auth/use-auth';
import { initiateLogin } from '../users/utils/auth-actions';
import { usePostLike } from './use-post-like';

function PostLikeButton({ post }: { post: TPost }) {
  const [{ user, isLoggedIn }] = useAuth();

  const [
    { myLikeData, likeLoading, liked, likeId },
    { updateLike, createLike },
  ] = usePostLike({
    post,
  });

  return (
    <div
      className='btn btn-outline rounded-full border-gray-400'
      onClick={() => {
        if (!isLoggedIn) {
          // open login
          initiateLogin();
          return;
        }

        if (likeLoading) {
          return;
        }

        if (!liked) {
          if (likeId) {
            updateLike({
              variables: {
                id: likeId,
                data: {
                  liked: true,
                  post: post.id,
                  user: user?.id,
                },
              },
            });
          } else {
            createLike({
              variables: {
                data: {
                  liked: true,
                  post: post.id,
                  user: user?.id,
                },
              },
            });
          }
        } else {
          updateLike({
            variables: {
              id: myLikeData!.id!,
              data: {
                liked: false,
                post: post.id,
                user: user?.id,
              },
            },
          });
        }
      }}
    >
      {liked ? (
        <IoMdHeart size='1.4rem' className={classNames('text-error')} />
      ) : (
        <IoMdHeart size='1.4rem' className={classNames('text-black')} />
      )}
      <span className={classNames(liked ? 'text-error' : 'text-black')}>
        {post.likeCount || 0}
      </span>
    </div>
  );
}

export default memo(PostLikeButton);
